import { useSelector } from '~/app/lib/store/redux';
import { useItemContext } from './ItemPageContext';
import { selectUserCountry } from '~/app/lib/store/session/selectors';
import {
  BAHRAIN,
  BELGIUM,
  CHINA,
  DENMARK,
  EGYPT,
  HONG_KONG,
  HUNGARY,
  INDIA,
  IRAN,
  IRAQ,
  JORDAN,
  KUWAIT,
  LEBANON,
  LUXEMBOURG,
  NETHERLANDS,
  NORWAY,
  OMAN,
  PHILIPPINES,
  QATAR,
  SAUDI_ARABIA,
  SOUTH_AFRICA,
  SOUTH_SUDAN,
  SUDAN,
  SYRIAN_ARAB_REPUBLIC,
  UNITED_ARAB_EMIRATES,
  YEMEN,
} from '~/app/lib/country/countryCodes';

export const PRERELEASE_BLOCKED_COUNTRIES = [
  HUNGARY,
  INDIA,
  PHILIPPINES,
  SOUTH_AFRICA,
  CHINA,
  HONG_KONG,

  // Benelux
  BELGIUM,
  LUXEMBOURG,
  NETHERLANDS,

  // Middle East
  UNITED_ARAB_EMIRATES,
  BAHRAIN,
  EGYPT,
  IRAQ,
  IRAN,
  JORDAN,
  KUWAIT,
  LEBANON,
  OMAN,
  QATAR,
  SAUDI_ARABIA,
  SUDAN,
  SOUTH_SUDAN,
  SYRIAN_ARAB_REPUBLIC,
  YEMEN,

  NORWAY,
  DENMARK,
];

export const useCountryBlocked = () => {
  const item = useItemContext();
  const country = useSelector(selectUserCountry);

  if (
    item.data.item.type === 'album' &&
    item.data.item.isPrerelease &&
    !item.data.item.isDraft &&
    !item.data.item.userCanEdit
  ) {
    return PRERELEASE_BLOCKED_COUNTRIES.includes(country);
  }

  return false;
};
