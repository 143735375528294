import { useEffect } from 'react';

import { useAppHydrated } from '~/app/lib/utils/onceAppHydrated';
import { AddonTypes } from '~/app/lib/songwhipApi/types';
import { useEmitter } from '~/app/lib/hooks/useEmitter';
import useOneTrust from '~/app/components/OneTrust/useOneTrust';
import { ItemContext } from '../types';

const useCustomTracking = (itemContext: ItemContext) => {
  const appHydrated = useAppHydrated();
  const oneTrust = useOneTrust();
  const emitter = useEmitter();

  const resolvedFacebookPixelId =
    AddonTypes.FB_PIXEL in itemContext.addons
      ? itemContext.addons[AddonTypes.FB_PIXEL]?.pixelId
      : itemContext.data.item.primaryOwnerAccount?.config?.facebookPixelId;

  const resolvedGoogleTagManagerId =
    itemContext.data.item.primaryOwnerAccount?.config?.googleTagManagerId;

  useEffect(() => {
    // don't block critical path
    if (!appHydrated) return;

    // don't load/run pixels is user hasn't opted-in
    if (!oneTrust().pixelsEnabled) return;

    let removeFacebookListeners;
    let isTornDown = false;

    if (resolvedFacebookPixelId) {
      import('./facebookPixel').then(({ installFacebookPixel }) => {
        // protect against zombie callback
        if (isTornDown) return;

        removeFacebookListeners = installFacebookPixel({
          pixelId: resolvedFacebookPixelId,
          emitter,
        });
      });
    }

    return () => {
      removeFacebookListeners?.();
      isTornDown = true;
    };
    // if `oneTrust` object changes it means user-prefs have updated
    // and we can re-attempt loading pixels etc
  }, [resolvedFacebookPixelId, appHydrated, oneTrust]);

  // each tracking script use its own hook to avoid
  // re-installing if other one changed
  useEffect(() => {
    if (!appHydrated) return;

    let removeGoogleTagManagerScript;

    if (!oneTrust().pixelsEnabled) {
      // remove GTM tracking script if user has opted out
      removeGoogleTagManagerScript?.();
      return;
    }

    let isTornDown = false;

    if (resolvedGoogleTagManagerId) {
      import('./googleTagManager').then(({ installGoogleTagManager }) => {
        if (isTornDown) return;

        removeGoogleTagManagerScript = installGoogleTagManager({
          gtmId: resolvedGoogleTagManagerId,
        });
      });
    }

    return () => {
      isTornDown = true;
    };
  }, [resolvedGoogleTagManagerId, appHydrated, oneTrust]);
};

export default useCustomTracking;
